import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StaticValuesService {
  private apiUrl;

  constructor(private http: HttpClient, public router: Router) {
    this.apiUrl = environment.apiUrl + 'staticvalues/';
  }  

  getCountries(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}countries`);
  }

  getLanguages(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}languages`);
  }

  getWebsiteTypes(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}websiteTypes`);
  }

  getPrices(): Observable<string[]> {
    return this.http.get<string[]>(`${this.apiUrl}prices`);
  }
}
