import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormDataService } from '../form-data.service';
import { UserDomainsService } from '../user-domains.service';

@Component({
  selector: 'app-publisher-step4',
  templateUrl: './publisher-step4.component.html',
  styleUrls: ['./publisher-step4.component.scss']
})
export class PublisherStep4Component implements OnInit {
  formData: any = {};
  activeTab: string = 'basic';

  constructor(
    private router: Router,
    private formDataService: FormDataService,
    private userDomainsService: UserDomainsService
  ) { }

  ngOnInit(): void {
    this.formData = this.formDataService.getFormData();
  }

  onFinalize() {
    this.userDomainsService.createUserDomain(this.formData).subscribe(response => {
      console.log('Form data submitted:', response);
      this.router.navigate(['/websites']);
    });
  }

  onBack() {
    this.router.navigate(['/publisher-step3']);
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  onTabClick(tab: number) {
    this.router.navigate([`/publisher-step${tab}`]);
  }

  triggerFileUpload(inputId: string) {
    const fileInput = document.getElementById(inputId) as HTMLInputElement | null;
    fileInput?.click();
  }
}
