import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PublisherComponent } from './publisher/publisher.component';
import { PublisherStep2Component } from './publisher-step2/publisher-step2.component';
import { PublisherStep1Component } from './publisher-step1/publisher-step1.component';
import { PublisherStep3Component } from './publisher-step3/publisher-step3.component';
import { PublisherStep4Component } from './publisher-step4/publisher-step4.component';
import { WebsitesComponent } from './websites/websites.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' }, // Redirect to login by default
  { path: 'login', component: LoginComponent },
  // Uncomment and add other routes as needed
  // { path: 'home', component: HomeComponent },
  { path: 'register', component: RegisterComponent },
  // { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'publisher', component: PublisherComponent },
  { path: 'publisher-step1', component: PublisherStep1Component },
  { path: 'publisher-step2', component: PublisherStep2Component },
  { path: 'publisher-step3', component: PublisherStep3Component },
  { path: 'publisher-step4', component: PublisherStep4Component },
  { path: 'websites', component: WebsitesComponent },
 
  
  // Add a wildcard route for 404 handling
 // { path: '**', redirectTo: '/login' } // Redirect any unknown paths to login
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
