<div class="container">
    <div class="left-side">
      <div class="content">
        <div class="logo-container">
          <img src="assets/Link-Building-Software.png" alt="AdverLink"> <!-- Adjust the logo path as needed -->
        </div>
        <h2>Welcome</h2>
        <p>Enter your login credentials to access your account</p>
        <div id="googleSignInButton"></div>
        <form (ngSubmit)="login()">
          <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" [(ngModel)]="loginData.email" name="email" placeholder="Enter your email" required>
          </div>
          <div class="form-group">
            <label for="password">Password:</label>
            <input type="password" id="password" [(ngModel)]="loginData.password" name="password" placeholder="Enter your password" required>
            <div class="form-footer">
              <a href="#" (click)="onForgotPassword($event)">Forgot Password?</a>
            </div>
          </div>
          <div class="remember-me">
            <input type="checkbox" id="remember-me" name="remember-me">
            <label for="remember-me">Remember me</label>
          </div>
          <button type="submit" class="btn">Log in</button>
        </form>
        <div *ngIf="messages.error" class="error-message">{{ messages.error }}</div>
        <div *ngIf="messages.success" class="success-message">{{ messages.success }}</div>
        <div class="form-footer">
          Don't have an account? <a href="#" (click)="onSignup($event)">Signup for free</a>
        </div>
      </div>
    </div>
    <div class="right-side">
      <img src="assets/Adverlink-Image-of-a-woman-looking-at-a-a-web.jpg" alt="Background Image"> <!-- Adjust the image path as needed -->
    </div>
  </div>
  