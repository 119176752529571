import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-logout',
  template: '<button (click)="logout()">Logout</button>'
})
export class LogoutComponent {
  constructor(private authService: AuthService, private router: Router) { }

  logout() {
    this.authService.logout(); // Implement logout logic in AuthService if not already implemented
    this.router.navigate(['/login']); // Redirect to login page after logout
  }
}
