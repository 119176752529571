import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormDataService } from '../form-data.service';
import { StaticValuesService } from '../static-values.service';
 

@Component({
  selector: 'app-publisher-step1',
  templateUrl: './publisher-step1.component.html',
  styleUrls: ['./publisher-step1.component.scss']
})
export class PublisherStep1Component implements OnInit {
  formData: any = {};
  countries: string[] = [];
  activeTab: string = 'basic';
  languages: string[] = [];
  websiteTypes: string[] = [];
  prices: string[] = [];

  constructor(
    private router: Router,
    private formDataService: FormDataService,    
    private staticValuesService: StaticValuesService
  ) { }

  ngOnInit(): void {
    this.formData = this.formDataService.getFormData();
    this.loadStaticValues();
  }

  onNext() {
    this.formDataService.setFormData(this.formData);
    this.router.navigate(['/publisher-step2']);
  }

  onBack() {
    this.router.navigate(['/publisher']);
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  onTabClick(tab: number) {
    this.router.navigate([`/publisher-step${tab}`]);
  }

  loadStaticValues(): void {
    this.staticValuesService.getCountries().subscribe({
      next: (data: string[]) => this.countries = data,
      error: (error: any) => console.error('Error fetching countries:', error)
    });

    this.staticValuesService.getLanguages().subscribe({
      next: (data: string[]) => this.languages = data,
      error: (error: any) => console.error('Error fetching languages:', error)
    });

    this.staticValuesService.getWebsiteTypes().subscribe({
      next: (data: string[]) => this.websiteTypes = data,
      error: (error: any) => console.error('Error fetching website types:', error)
    });

    this.staticValuesService.getPrices().subscribe({
      next: (data: string[]) => this.prices = data,
      error: (error: any) => console.error('Error fetching prices:', error)
    });
  }
}
