import { Component } from '@angular/core';
import { AuthService, RegisterRequest } from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent {
  formData: RegisterRequest = {
    email: '',
    password: '',
    userType: '',
    news: false
  };
  messages: any = {
    error: '',
    success: ''
  };

  constructor(private authService: AuthService, private router: Router) { }

  register() {
    this.authService.register(this.formData).subscribe({
      next: (response: any) => {
        this.messages.success = 'Account created successfully!';
        this.messages.error = '';
        this.router.navigate(['/home']); // Redirect to home page
      },
      error: (error: any) => {
        this.messages.error = 'This email address is already in use. Try a different email address or restore your password.';
        this.messages.success = '';
      }
    });
  }
}
