import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserDomainsService {
  private readonly apiUrl: string;

  constructor(private http: HttpClient,  private authService: AuthService) {
    this.apiUrl = environment.apiUrl + 'userdomains/';
  }

  createUserDomain(data: any): Observable<any> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.post(`${this.apiUrl}/create`, data, { headers });
  }

  getUserDomains(): Observable<any[]> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.get<any[]>(`${this.apiUrl}/get`, { headers });
  }
  updateSecretKey(domain: string): Observable<string> {
    const token = this.authService.getToken();
    const headers = new HttpHeaders().set('Authorization', `Bearer ${token}`);
    return this.http.put<string>(`${this.apiUrl}/update-secret-key/${domain}`, {}, { headers });
  }
}
