<div class="container">
    <div class="sidebar">
      <div class="logo">
        <img src="assets/logo.png" alt="AdverLink" style="width: 150px;">
      </div>
      <h3>My Portals</h3>
      <a href="#">Websites</a>
      <a href="#">Links reports</a>
      <h3>My Account</h3>
      <a href="#">My account</a>
      <a href="#">Platform language</a>
      <a href="#">Users</a>
      <a href="#">Billing</a>
      <a href="#">Invoices</a>
      <h3>Wordpress Plugin</h3>
      <a href="#">Download</a>
      <a href="#">How to use it</a>
    </div>
    <div class="main">
      <div class="header">
        <h2>Publisher</h2>
        <div class="user-info">
          <span>Hakan Ertan</span>
          <img src="assets/user-icon.png" alt="User">
          <img src="assets/notifications-icon.png" alt="Notifications">
          <img src="assets/settings-icon.png" alt="Settings">
        </div>
      </div>
      <p>(website owner, blog owner, corporate websites, personal websites)</p>
      <div class="nav-tabs">
        <a (click)="onTabClick(1)">Basic data</a>
        <a (click)="onTabClick(2)" >Theme</a>
        <a (click)="onTabClick(3)"  class="active">Popularity</a>
        <a (click)="onTabClick(4)">Statistics</a>
      </div>
      <form (ngSubmit)="onNext()">
        <div class="form-group">
          <label for="unique-users">Number of unique users per month *</label>
          <input type="text" id="unique-users" name="unique-users" [(ngModel)]="formData.uniqueUsers" placeholder="Number of visitors" required>
        </div>
        <div class="form-group">
          <label for="file-upload">Attach files documenting the number of visitors (PDF) *</label>
          <div class="file-input" (click)="triggerFileUpload()">
            Select file or drag it here
          </div>
          <input type="file" id="file-upload" name="file-upload" style="display: none;" (change)="handleFileInput($event)" required>
          <div class="tooltip">Add statistics generated directly from Google Analytics for the last three months documenting the number of unique users.</div>
        </div>
        <div class="form-actions">
          <button type="button" class="btn back" (click)="onBack()">Back</button>
          <button type="submit" class="btn">Next</button>
        </div>
      </form>
    </div>
  </div>
  