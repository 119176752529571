import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

declare var google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginData: any = {
    email: '',
    password: ''
  };

  messages: any = {
    error: '',
    success: ''
  };


  constructor(private authService: AuthService, private router: Router) { }

  login() {
    this.authService.login(this.loginData).subscribe({
      next: (response: any) => {
        this.messages.success = 'Login successful!';
        this.messages.error = '';
        this.router.navigate(['/publisher']); // Redirect to home page
      },
      error: (error: any) => {
        this.messages.error = 'Invalid email or password.';
        this.messages.success = '';
      }
    });
  }

  ngOnInit(): void {
    google.accounts.id.initialize({
      client_id: "1048634306191-k05g3uogid9d6vpp2ltl4pquon2r79cq.apps.googleusercontent.com",
      
      callback: (response: any) => this.handleGoogleSignIn(response)
    });
    google.accounts.id.renderButton(
      document.getElementById("googleSignInButton"),
      { size: "large", type: "icon" }  // customization attributes
    );
  }
  
  /*handleGoogleSignIn(response: any) {
    console.log(response.credential);
debugger
    // This next is for decoding the idToken to an object if you want to see the details.
    let base64Url = response.credential.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    console.log(JSON.parse(jsonPayload));
    this.router.navigate(['/publisher']);
  }*/ 
  handleGoogleSignIn(response: any) {
    console.log(response.credential);
  
    // Decode the idToken
    let base64Url = response.credential.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    console.log(JSON.parse(jsonPayload));
  
    // Call the googleSignIn method from the AuthService
    this.authService.googleSignIn(response.credential).subscribe({
      next: () => {
        this.router.navigate(['/publisher']);
      },
      error: (error) => {
        console.error('Google sign-in failed:', error);
      }
    });
  }
  
  onForgotPassword(event: Event) {
    event.preventDefault();
    console.log('Forgot password clicked');
    // Implement forgot password logic here
  }

  onSignup(event: Event) {
    event.preventDefault();
    this.router.navigate(['/register']);
  }

}
