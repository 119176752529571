<div class="container">
    <div class="sidebar">
      <div class="logo">
        <img src="assets/logo.png" alt="AdverLink" style="width: 150px;">
      </div>
      <h3>My Portals</h3>
      <a href="#">Websites</a>
      <a href="#">Links reports</a>
      <h3>My Account</h3>
      <a href="#">My account</a>
      <a href="#">Platform language</a>
      <a href="#">Users</a>
      <a href="#">Billing</a>
      <a href="#">Invoices</a>
      <h3>Wordpress Plugin</h3>
      <a href="#">Download</a>
      <a href="#">How to use it</a>
    </div>
    <div class="main">
      <div class="header">
        <h2>Publisher</h2>
        <div class="user-info">
          <span>Hakan Ertan</span>
          <img src="assets/user-icon.png" alt="User">
          <img src="assets/notifications-icon.png" alt="Notifications">
          <img src="assets/settings-icon.png" alt="Settings">
        </div>
      </div>
      <p>(website owner, blog owner, corporate websites, personal websites)</p>
      <div class="nav-tabs">
        <a (click)="onTabClick(1)"  >Basic data</a>
        <a (click)="onTabClick(2)"   class="active">Theme</a>
        <a (click)="onTabClick(3)" >Popularity</a>
        <a (click)="onTabClick(4)"  >Statistics</a>
      </div>
      <form (ngSubmit)="onNext()">
        <div class="form-group">
          <label for="description">Short Description *</label>
          <textarea id="description" name="description" rows="4" maxlength="600" [(ngModel)]="formData.description" placeholder="Enter the description of your website (maximum 600 characters)" required></textarea>
        </div>
        <div class="form-group">
          <label for="tags">Tags *</label>
          <input type="text" id="tags" name="tags" [(ngModel)]="formData.tags" placeholder="Enter your tags" required>
          <div class="tooltip">Maximum 10 numbers of tags</div>
        </div>
        <div class="form-group">
          <label>I accept keywords and links from business in:</label>
          <div class="checkbox-group">
            <input type="checkbox" id="gambling" name="business[]" [(ngModel)]="formData.businessGambling" value="Gambling">
            <label for="gambling">Gambling</label>
          </div>
          <div class="checkbox-group">
            <input type="checkbox" id="cbd" name="business[]" [(ngModel)]="formData.businessCbd" value="CBD">
            <label for="cbd">CBD</label>
          </div>
          <div class="checkbox-group">
            <input type="checkbox" id="cryptocurrencies" name="business[]" [(ngModel)]="formData.businessCryptocurrencies" value="Cryptocurrencies">
            <label for="cryptocurrencies">Cryptocurrencies</label>
          </div>
          <div class="checkbox-group">
            <input type="checkbox" id="erotica" name="business[]" [(ngModel)]="formData.businessErotica" value="Erotica">
            <label for="erotica">Erotica</label>
          </div>
          <div class="checkbox-group">
            <input type="checkbox" id="dietary-supplements" name="business[]" [(ngModel)]="formData.businessDietarySupplements" value="Dietary supplements and pharmacy">
            <label for="dietary-supplements">Dietary supplements and pharmacy</label>
          </div>
          <div class="checkbox-group">
            <input type="checkbox" id="loans" name="business[]" [(ngModel)]="formData.businessLoans" value="Loans">
            <label for="loans">Loans</label>
          </div>
        </div>
        <div class="form-group">
          <label for="blocked-websites">List of blocked websites *</label>
          <textarea id="blocked-websites" name="blocked-websites" rows="2" [(ngModel)]="formData.blockedWebsites" placeholder="Enter the list comma separated" required></textarea>
        </div>
        <div class="form-actions">
          <button type="button" class="btn back" (click)="onBack()">Back</button>
          <button type="submit" class="btn">Next</button>
        </div>
      </form>
    </div>
  </div>
  