import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
//import { NavMenuComponent } from './nav-menu/nav-menu.component';
//import { HomeComponent } from './home/home.component';
//import { CounterComponent } from './counter/counter.component';
//import { FetchDataComponent } from './fetch-data/fetch-data.component';
//import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { PublisherComponent } from './publisher/publisher.component';
import { PublisherStep1Component } from './publisher-step1/publisher-step1.component';
import { LogoutComponent } from './logout/logout.component';
import { PublisherStep2Component } from './publisher-step2/publisher-step2.component';
import { PublisherStep3Component } from './publisher-step3/publisher-step3.component';
import { PublisherStep4Component } from './publisher-step4/publisher-step4.component';
import { WebsitesComponent } from './websites/websites.component';
//import { UserDomainsComponent } from './user-domains/user-domains.component';
//import { ResetPasswordComponent } from './reset-password/reset-password.component';
//import { PublisherComponent } from './publisher/publisher.component';
//import { PublisherStep1Component } from './publisher-step1/publisher-step1.component';
//import { PublisherStep2Component } from './publisher-step2/publisher-step2.component';
//import { PublisherStep3Component } from './publisher-step3/publisher-step3.component';
//import { PublisherStep4Component } from './publisher-step4/publisher-step4.component';
//import { WebsitesComponent } from './websites/websites.component';

@NgModule({
  declarations: [
    AppComponent,
    //NavMenuComponent,
    //HomeComponent,
    //CounterComponent,
    //FetchDataComponent,
    //RegisterComponent,
    LoginComponent,
    RegisterComponent,
    PublisherComponent,
    PublisherStep1Component,
    LogoutComponent,
    PublisherStep2Component,
    PublisherStep3Component,
    PublisherStep4Component,
    WebsitesComponent,
    //UserDomainsComponent,
    //ResetPasswordComponent,
    //PublisherComponent,
    //PublisherStep1Component,
    //PublisherStep2Component,
    //PublisherStep3Component,
    //PublisherStep4Component,
    //WebsitesComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    FormsModule    
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
