<div class="container">
    <div class="sidebar">
      <div class="logo">
        <img src="assets/logo.png" alt="AdverLink" style="width: 150px;">
      </div>
      <h3>My Portals</h3>
      <a href="#">Websites</a>
      <a href="#">Links reports</a>
      <h3>My Account</h3>
      <a href="#">My account</a>
      <a href="#">Platform language</a>
      <a href="#">Users</a>
      <a href="#">Billing</a>
      <a href="#">Invoices</a>
      <h3>Wordpress Plugin</h3>
      <a href="#">Download</a>
      <a href="#">How to use it</a>
    </div>
    <div class="main">
      <div class="header">
        <h2>Publisher</h2>
        <div class="user-info">
          <span>Hakan Ertan</span>
          <img src="assets/user-icon.png" alt="User">
          <img src="assets/notifications-icon.png" alt="Notifications">
          <img src="assets/settings-icon.png" alt="Settings">
        </div>
      </div>
      <p>(website owner, blog owner, corporate websites, personal websites)</p>
      <div class="nav-tabs">
        <a (click)="onTabClick(1)" class="active">Basic data</a>
        <a (click)="onTabClick(2)">Theme</a>
        <a (click)="onTabClick(3)">Popularity</a>
        <a (click)="onTabClick(4)">Statistics</a>
      </div>
      <form (ngSubmit)="onNext()">
        <div class="form-group">
          <label for="domain">Domain *</label>
          <input type="text" id="domain" name="domain" [(ngModel)]="formData.domain" required>
        </div>
        <div class="form-group">
          <label for="language">Language of publication *</label>
          <select id="language" name="language" [(ngModel)]="formData.language" required>
            <option *ngFor="let language of languages" [value]="language">{{ language }}</option>
          </select>
          <div class="tooltip">Possible variants: Bulgarian, Croatian, etc.</div>
        </div>
        <div class="form-group">
          <label for="country">Country *</label>
          <select id="country" name="country" [(ngModel)]="formData.country" required>
            <option *ngFor="let country of countries" [value]="country">{{ country }}</option>
          </select>
        </div>
        <div class="form-group">
          <label for="website-type">Type of website *</label>
          <select id="website-type" name="website-type" [(ngModel)]="formData.websiteType" required>
            <option *ngFor="let type of websiteTypes" [value]="type">{{ type }}</option>
          </select>
        </div>
        <div class="checkbox-group form-group">
          <input type="checkbox" id="indexing" name="indexing" [(ngModel)]="formData.indexing">
          <label for="indexing">YES, I hereby grant explicit permission for the AdverLink Bot to crawl this website.</label>
        </div>
        <div class="form-group">
          <label for="price">Price per link *</label>
          <select id="price" name="price" [(ngModel)]="formData.price" required>
            <option *ngFor="let price of prices" [value]="price">{{ price }}</option>
          </select>
        </div>
        <div class="form-actions">
          <button type="button" class="btn back" (click)="onBack()">Back</button>
          <button type="submit" class="btn">Next</button>
        </div>
      </form>
    </div>
  </div>
  