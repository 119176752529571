<div class="container">
    <div class="sidebar">
      <div class="logo">
        <img src="assets/logo.png" alt="AdverLink" style="width: 150px;">
      </div>
      <h3>My Portals</h3>
      <a href="#">Websites</a>
      <a href="#">Links reports</a>
      <h3>My Account</h3>
      <a href="#">My account</a>
      <a href="#">Platform language</a>
      <a href="#">Users</a>
      <a href="#">Billing</a>
      <a href="#">Invoices</a>
      <h3>Wordpress Plugin</h3>
      <a href="#">Download</a>
      <a href="#">How to use it</a>
    </div>
    <div class="main">
      <div class="header">
        <h2>Publisher</h2>
        <div class="user-info">
          <span>Hakan Ertan</span>
          <img src="assets/user-icon.png" alt="User">
          <img src="assets/notifications-icon.png" alt="Notifications">
          <img src="assets/settings-icon.png" alt="Settings">
        </div>
      </div>
      <p>(website owner, blog owner, corporate websites, personal websites)</p>
      <div class="rules">
        <h3>Rules to subscribe your website:</h3>
        <p><i class="fa fa-check"></i> The site must be Indexed in Google</p>
        <p><i class="fa fa-check"></i> The authority should be over 10</p>
        <p><i class="fa fa-check"></i> Must have original content (articles, tips, news)</p>
      </div>
      <button class="btn" (click)="navigateToStep1()">Add your website</button>

    </div>
  </div>
  