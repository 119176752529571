import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormDataService } from '../form-data.service';

@Component({
  selector: 'app-publisher-step2',
  templateUrl: './publisher-step2.component.html',
  styleUrls: ['./publisher-step2.component.scss']
})
export class PublisherStep2Component implements OnInit {
  formData: any = {};
  activeTab: string = 'theme';

  constructor(
    private router: Router,
    private formDataService: FormDataService
  ) { }

  ngOnInit(): void {
    this.formData = this.formDataService.getFormData();
  }

  onNext() {
    this.formDataService.setFormData(this.formData);
    this.router.navigate(['/publisher-step3']);
  }

  onBack() {
    this.router.navigate(['/publisher-step1']);
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  onTabClick(tab: number) {
    this.router.navigate([`/publisher-step${tab}`]);
  }
}
