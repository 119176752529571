import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-publisher',
  templateUrl: './publisher.component.html',
  styleUrls: ['./publisher.component.scss']
})
export class PublisherComponent {
  constructor(private router: Router) { }

  navigateToStep1() {
    this.router.navigate(['/publisher-step1']);
  }
}
