<div class="container">
    <div class="logo">
      <img src="assets/logo.png" alt="AdverLink">
    </div>
    <h2>Create your account</h2>
    
    <div class="messages">
      <div class="error" *ngIf="messages.error">{{ messages.error }}</div>
      <div class="success" *ngIf="messages.success">{{ messages.success }}</div>
    </div>
    <form (ngSubmit)="register()">
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" name="email" [(ngModel)]="formData.email" required>
      </div>
      <div class="form-group">
        <label for="password">Password:</label>
        <input type="password" id="password" name="password" [(ngModel)]="formData.password" required>
        <div class="tooltip">Secure password tips: More than 8 characters, Both lowercase and uppercase letters, At least one numeric character</div>
      </div>
      <div class="form-group">
        <label for="userType">User type:</label>
        <select id="userType" name="userType" [(ngModel)]="formData.userType" required>
          <option value="advertiser">Advertiser - Link buyer</option>
          <option value="publisher">Publisher - Link seller</option>
        </select>
      </div>
      <div class="form-group checkbox">
        <input type="checkbox" id="news" name="news" [(ngModel)]="formData.news">
        <label for="news">Receive AdverLink news and SEO link building tips</label>
      </div>
      <button type="submit" class="btn">Create account</button>
    </form>
    <div class="form-footer">
      Already have an account? <a routerLink="/login">Log in</a>
    </div>
    <div class="form-footer">
      By creating your account, you agree to the <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
    </div>
  </div>
  