import { Component, OnInit } from '@angular/core';
import { UserDomainsService } from '../user-domains.service';

@Component({
  selector: 'app-websites',
  templateUrl: './websites.component.html',
  styleUrls: ['./websites.component.scss']
})
export class WebsitesComponent implements OnInit {
  userDomains: any[] = [];

  constructor(private userDomainsService: UserDomainsService) { }

  ngOnInit(): void {
    this.userDomainsService.getUserDomains().subscribe(data => {
      this.userDomains = data;
    });
  }

  copyToClipboard(secretKey: string): void {
    navigator.clipboard.writeText(secretKey).then(() => {
      alert('Secret key copied to clipboard!');
    }, () => {
      alert('Failed to copy secret key.');
    });
  }

  refreshSecretKey(domain: string): void {
    this.userDomainsService.updateSecretKey(domain).subscribe(newKey => {
      const domainObj = this.userDomains.find(d => d.domain === domain);
      if (domainObj) {
        domainObj.secretKey = newKey;
      }
    });
  }
}
