<div class="container">
    <div class="sidebar">
      <div class="logo">
        <img src="assets/logo.png" alt="AdverLink" style="width: 150px;">
      </div>
      <h3>My Portals</h3>
      <a href="#">Websites</a>
      <a href="#">Links reports</a>
      <h3>My Account</h3>
      <a href="#">My account</a>
      <a href="#">Platform language</a>
      <a href="#">Users</a>
      <a href="#">Billing</a>
      <a href="#">Invoices</a>
      <h3>Wordpress Plugin</h3>
      <a href="#">Download</a>
      <a href="#">How to use it</a>
    </div>
    <div class="main">
      <div class="header">
        <h2>Publisher</h2>
        <div class="user-info">
          <span>Hakan Ertan</span>
          <img src="assets/user-icon.png" alt="User">
          <img src="assets/notifications-icon.png" alt="Notifications">
          <img src="assets/settings-icon.png" alt="Settings">
        </div>
      </div>
      <p>(website owner, blog owner, corporate websites, personal websites)</p>
      <div class="nav-tabs">
        <a (click)="onTabClick(1)" >Basic data</a>
        <a (click)="onTabClick(2)" >Theme</a>
        <a (click)="onTabClick(3)" >Popularity</a>
        <a (click)="onTabClick(4)"  class="active">Statistics</a>
      </div>
      <form (ngSubmit)="onFinalize()">
        <div class="form-group">
          <div class="btn-google" (click)="triggerFileUpload('google-analytics')">
            <img src="assets/google-icon.png" alt="Google">
            Connect Google Analytics
          </div>
          <input type="file" id="google-analytics" name="google-analytics" style="display: none;">
        </div>
        <div class="form-group">
          <div class="btn-google" (click)="triggerFileUpload('google-search-console')">
            <img src="assets/google-icon.png" alt="Google">
            Connect Google Search Console
          </div>
          <input type="file" id="google-search-console" name="google-search-console" style="display: none;">
        </div>
        <div class="form-actions">
          <button type="button" class="btn back" (click)="onBack()">Back</button>
          <button type="submit" class="btn">Finalize</button>
        </div>
      </form>
    </div>
  </div>
  