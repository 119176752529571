import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormDataService } from '../form-data.service';

@Component({
  selector: 'app-publisher-step3',
  templateUrl: './publisher-step3.component.html',
  styleUrls: ['./publisher-step3.component.scss']
})
export class PublisherStep3Component implements OnInit {
  formData: any = {};
  activeTab: string = 'popularity';

  constructor(
    private router: Router,
    private formDataService: FormDataService
  ) { }

  ngOnInit(): void {
    this.formData = this.formDataService.getFormData();
  }

  onNext() {
    this.formDataService.setFormData(this.formData);
    this.router.navigate(['/publisher-step4']);
  }

  onBack() {
    this.router.navigate(['/publisher-step2']);
  }

  setActiveTab(tab: string) {
    this.activeTab = tab;
  }

  onTabClick(tab: number) {
    this.router.navigate([`/publisher-step${tab}`]);
  }

  triggerFileUpload() {
    const fileInput = document.getElementById('file-upload') as HTMLInputElement | null;
    fileInput?.click();
  }

  handleFileInput(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input.files ? input.files[0] : null;
    if (file) {
      console.log('Selected file:', file);
    }
  }
}
