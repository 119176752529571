<div class="container">
  <div class="sidebar">
    <div class="logo">
      <img src="assets/logo.png" alt="AdverLink" style="width: 150px;">
    </div>
    <h3>My Portals</h3>
    <a href="#">Websites</a>
    <a href="#">Links reports</a>
    <h3>My Account</h3>
    <a href="#">My account</a>
    <a href="#">Platform language</a>
    <a href="#">Users</a>
    <a href="#">Billing</a>
    <a href="#">Invoices</a>
    <h3>Wordpress Plugin</h3>
    <a href="#">Download</a>
    <a href="#">How to use it</a>
  </div>
  <div class="main">
    <div class="header">
      <h2>Publisher</h2>
      <div class="user-info">
        <span>Hakan Ertan</span>
        <img src="assets/user-icon.png" alt="User">
        <img src="assets/notifications-icon.png" alt="Notifications">
        <img src="assets/settings-icon.png" alt="Settings">
      </div>
    </div>
    <p>(website owner, blog owner, corporate websites, personal websites)</p>
    <table>
      <thead>
        <tr>
          <th>Portal</th>
          <th>Type</th>
          <th>Country/Region</th>
          <th>Unique Users</th>
          <th>AHREFS - Organic Traffic</th>
          <th>Semrush</th>
          <th>Trust Flow</th>
          <th>UR Medium</th>
          <th>Dofollow</th>
          <th>Traffic Tracking</th>
          <th>Qualitative Evaluation</th>
          <th>Technical Evaluation</th>
          <th>Secret Key</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let domain of userDomains">
          <td>{{ domain.domain }}</td>
          <td>{{ domain.websiteType }}</td>
          <td>{{ domain.country }}</td>
          <td>{{ domain.uniqueUsers }}</td>
          <td><img src="assets/ahrefs-icon.png" alt="Ahrefs"></td>
          <td><img src="assets/semrush-icon.png" alt="Semrush"></td>
          <td>{{ domain.trustFlow }}</td>
          <td>{{ domain.urMedium }}</td>
          <td><img *ngIf="domain.dofollow" src="assets/check-icon.png" alt="Dofollow"><img *ngIf="!domain.dofollow" src="assets/cross-icon.png" alt="No Dofollow"></td>
          <td><img *ngIf="domain.trafficTracking" src="assets/check-icon.png" alt="Traffic"><img *ngIf="!domain.trafficTracking" src="assets/cross-icon.png" alt="No Traffic"></td>
          <td>{{ domain.qualitativeEvaluation }}/10</td>
          <td>{{ domain.technicalEvaluation }}/10</td>
          <td>
            {{ domain.secretKey }}
            <img src="assets/copy-icon.png" alt="Copy" (click)="copyToClipboard(domain.secretKey)" style="cursor: pointer;">
            <img src="assets/refresh-icon.png" alt="Refresh" (click)="refreshSecretKey(domain.domain)" style="cursor: pointer; margin-left: 5px;">
          </td>
          <td><button class="btn btn-edit">Edit</button></td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
